.listItem::after{
  content: "";
  width: 50px;
  height: 2px;
  background-color: #D5A021;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  opacity: 0;
  transition-duration: 150ms;
}

.listItem.active::after{
  opacity: 100;
  bottom: -20%;
  color: #D5A021;
}
